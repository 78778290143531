<template>
  <div class="vx-col w-full">
    <vx-card title="แก้ไขข้อมูลพนักงาน">
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-input v-validate="'required|alpha_num'" class="w-full" label-placeholder="Username" name="username"
            v-model="new_data.user" />
          <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-input v-validate="'required'" class="w-full" label-placeholder="NameE" name="name"
            v-model="new_data.name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-input v-validate="'required|alpha_num'" class="w-full" label-placeholder="Password" name="password"
            v-model="new_data.pass" />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-select class="w-full mt-4" v-model="new_data.group">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in datagroups" />
          </vs-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-6">
          <p>สถานะ Login</p>
          <br>
          <vs-switch v-model="new_data.status">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </div>
      </div>


      <vx-card class="mt-base" no-shadow card-border>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Permissions</span>

            </div>
            <vs-divider />
          </div>
          <div class="vx-col w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5">
            <div class="flex items-end px-3">
              <vs-button color="primary" class="my-2 small" @click="read()">เลือก Read ทั้งหมด</vs-button>
            </div>

          </div>
          <div class="vx-col w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5">
            <div class="flex items-end px-3">
              <vs-button color="primary" class="my-2 small" @click="action ()">เลือก Action ทั้งหมด</vs-button>
            </div>
          </div>
          <div class="vx-col w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5">
            <div class="flex items-end px-3">
              <vs-button color="primary" class="my-2 small" @click="clear ()">ล้างข้อมูล</vs-button>
            </div>

          </div>
        </div>

        <div class="block overflow-x-auto">
          <vs-table :data="permissions">
            <template slot="thead">
              <vs-th>Permissions</vs-th>
              <vs-th></vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr,indextr) in data">
                <vs-td :data="tr.permissions_info">
                  {{tr.permissions_info}}
                </vs-td>
                  <vs-td>
                    <vs-checkbox v-if="tr.show_read && newUserPermissions[tr.permissions_code]" v-model="newUserPermissions[tr.permissions_code].read">Read</vs-checkbox>
                  </vs-td>
                  <vs-td>
                    <vs-checkbox v-if="tr.show_action && newUserPermissions[tr.permissions_code]" v-model="newUserPermissions[tr.permissions_code].action">Action</vs-checkbox>
                  </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>

      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="edit_staff">แก้ไขยูสเซอร์</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import axios from '../../axios'

import vSelect from 'vue-select'
import {
  Validator
} from 'vee-validate'

const dict = {
  custom: {
    name: {
      required: 'กรุณากรอกข้อมูล'
    },
    username: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น'
    },
    password: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น'
    },
    permissions: {
      required: 'กรุณากรอกข้อมูล'
    }

  }
}

// register custom messages
Validator.localize('en', dict)

export default {
  components: {
    vSelect
  },
  data () {
    return {
      datastaff: [],
      permissions: [],
      groups: 0,
      datagroups: [
        {
          value: 0,
          text: 'ตลอดเวลา'
        },
        {
          value: 1,
          text: 'กะเช้า'
        },
        {
          value: 2,
          text: 'กะดึก'
        },
        {
          value: 3,
          text: 'ชั่วคราว'
        }
      ],
      new_data: {
        user: '',
        name: '',
        pass: '',
        status: false,
        level: 0,
        group: 0,
        permissions: {}
      },
      log_info: '',
      newUserPermissions: {}
    }
  },
  async created () {
    const getStaff = await axios
      .get(`settingstaff/getstaff/${this.$route.params.id}`)

    this.datastaff = getStaff.data
    this.new_data = {
      user: this.datastaff[0].admin_user,
      name: this.datastaff[0].admin_name,
      pass: this.datastaff[0].admin_pass,
      status: this.datastaff[0].admin_status,
      level: this.datastaff[0].admin_level,
      group: this.datastaff[0].admin_group
    }

    this.newUserPermissions = JSON.parse(JSON.stringify(this.datastaff[0].admin_permissions))


    const getPermission = await axios
      .get('settingstaff/getpermission')
      .catch(console.error)

    this.permissions = getPermission.data

    this.permissions.forEach(permission => {
      if (!this.newUserPermissions[permission.permissions_code]) {
        this.$set(this.newUserPermissions, permission.permissions_code, {
          read: false,
          action: false
          // other default settings
        })
      }

    })
  },
  methods: {
    async read () {
      for (const row of this.permissions) {
        this.$set(this.newUserPermissions[row.permissions_code], 'read', true)
      }
    },

    async action () {
      for (const row of this.permissions) {
        this.$set(this.newUserPermissions[row.permissions_code], 'action', true)
      }
    },

    async clear () {
      for (const row of this.permissions) {
        this.$set(this.newUserPermissions[row.permissions_code], 'read', false)
        this.$set(this.newUserPermissions[row.permissions_code], 'action', false)
      }
    },

    async edit_staff () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          await axios
            .post('settingstaff/editstaff', {
              id: this.$route.params.id,
              name: this.new_data.name,
              username: this.new_data.user,
              password: this.new_data.pass,
              groups: this.new_data.group,
              status: this.new_data.status,
              permissions: this.newUserPermissions
            }).then(response => (this.check_added = response.data))

          if (this.check_added.status === true) {
            this.$vs.notify({
              time: 5000,
              color: 'success',
              position: 'top-right',
              icon: 'checkbox',
              title: 'แก้ไขยูสเซอร์เสร็จสิ้น',
              text: 'ทำรายการสำเร็จ'
            })


            this.$router.push('/settingstaff')
          } else {
            this.$vs.notify({
              time: 5000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'แก้ไขยูสเซอร์ไม่สำเร็จ',
              text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
            })
          }
        } else {
          this.$vs.notify({
            time: 2000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ไม่สามารถแก้ยูสเซอร์ได้',
            text: 'กรุณากรอกข้อมูลให้ครบ'
          })
        }
      })

    },
    async comparedata () {
      if (this.datastaff[0].admin_name !== this.new_data.name) {
        this.log_info += `ชื่อ : ${  this.datastaff[0].admin_name  } > ${  this.new_data.name} `
      }
      if (this.datastaff[0].admin_user !== this.new_data.user) {
        this.log_info += `ยูสเซอร์เนม : ${  this.datastaff[0].admin_user  } > ${  this.new_data.user} `
      }
      if (this.datastaff[0].admin_pass !== this.new_data.pass) {
        this.log_info += `พาสเวิร์ด : ${  this.datastaff[0].admin_pass  } > ${  this.new_data.pass} `
      }
      if (this.datastaff[0].admin_group !== this.new_data.group) {
        this.log_info += `เวลาทำงาน : ${  this.datastaff[0].admin_group  } > ${  this.new_data.group} `
      }
      if (this.datastaff[0].admin_status !== this.new_data.status) {
        this.log_info += `สถานะล็อกอิน : ${  this.datastaff[0].admin_status  } > ${  this.new_data.status} `
      }
      if (this.newUserPermissions !== this.datastaff[0].admin_permissions) {
        this.log_info += `สิทธิ์การเข้าถึง : ${  this.datastaff[0].admin_permissions  } > ${  this.newUserPermissions} `
      }
    }
  }
}

</script>

<style>

</style>
